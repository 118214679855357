import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BreadcrumbsType } from './types'

const Breadcrumbs = ({ breadcrumbs, ...props }) => {
  return (
    <BreadcrumbsList {...props}>
      {breadcrumbs.map((obj, key) => (
        <li key={key}>
          {obj.link &&
            (key !== breadcrumbs.length - 1 ? (
              <Link to={obj.link}>{obj.displayName || obj.name}</Link>
            ) : (
              <>{obj.displayName || obj.name}</>
            ))}
        </li>
      ))}
    </BreadcrumbsList>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: BreadcrumbsType,
}

const BreadcrumbsList = styled.ul`
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  li {
    color: #5d5d5d;
    display: inline-block;
    font-size: 1.05em;
    padding: 0;

    :last-of-type:after {
      content: '';
    }

    :after {
      content: '>';
      display: inline-block;
      margin: 0 5px;
    }

    a {
      color: #377539;
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 800px) {
    li {
      font-size: 0.9em;
    }
  }
`

export default React.memo(Breadcrumbs)
