import { arrayOf, string, shape } from 'prop-types'

export const BreadcrumbsType = arrayOf(
  shape({
    displayName: string,
    link: string,
    name: string,
    schemaName: string,
  })
)
