import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import { graphql } from 'gatsby'
import Breadcrumbs from 'components/breadcrumbs'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import Promos from 'components/promos'
import MultiStepForm from 'components/forms/multi-step-form'
import { steps } from 'components/forms/service'

const LocationServiceTemplate = ({
  data: {
    allHutsonPromotion,
    sanityLocation: { serviceDescriptionHtml, slug, title },
  },
  location: { pathname },
}) => {
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.edges.forEach(edge => {
      promos.push(edge.node)
    })
  }

  const meta = {
    title: `Service - Hutson in ${title} | Hutson Inc`,
    description: `Schedule a service at Hutson in ${title}. We service all STIHL equipment, plus all makes and models of lawn mowers, XUVs, tractors, large ag equipment, and more.`,
  }

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Locations',
      link: '/locations/',
    },
    {
      name: title,
      link: slug,
    },
    {
      name: 'Service',
      link: `${pathname}/`,
    },
  ]
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <H1>Schedule a service at Hutson in {title}</H1>
        <div dangerouslySetInnerHTML={{ __html: serviceDescriptionHtml }} />

        <StyledMultiStepForm steps={steps} name='Schedule Service' locationOverride={title} />

        <Promos data={promos} type={`Hutson in ${title} Service`} />
      </Content>
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const StyledMultiStepForm = styled(MultiStepForm)`
  margin: 40px 0;

  @media (min-width: 800px) {
    margin: 80px 0;
  }
`

export const pageQuery = graphql`
  query locationServiceQuery($id: String!) {
    sanityLocation(id: { eq: $id }) {
      serviceDescriptionHtml
      slug
      title
    }
    allHutsonPromotion(
      filter: { tags: { in: ["service"] } }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...HutsonPromo
        }
      }
    }
  }
`

export default LocationServiceTemplate
